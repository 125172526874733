/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { getmaxLevel } from "../../utils/backendApi";
import "../../css/ProjectTiles.css";
import "../../css/NewCard.css";
import { useMetaMask } from "metamask-react";
import NewCard from "./NewCard";
import { io } from "socket.io-client";

export const ProjectTiles = ({dashboardLoading, balanceAmount, list, amount }) => {
  const socket = io("wss://yetimarketbackend.0xytocin.online/");
  const [maxlevel, setMaxlevel] = useState(10);
  const { account, status } = useMetaMask();
  useEffect(() => {
    const max = async () => {
      await getmaxLevel(account).then((data) => {
        setMaxlevel(data.maxLevel);
      });
    };
    if (status === "connected") {
      max();
    }
  }, [status]);
  return (
    <div className="projectTile_container">
      {list && list.length > 0 ? (
        <div className="projectTile justifyContentodd">
          {list &&
            list.map((value) => (
              <NewCard
              dashboardLoading={dashboardLoading}
                balanceAmount={balanceAmount}
                key={value.projectname}
                value={value}
                amount={amount}
                maxlevel={maxlevel}
                projectName={value.projectname}
                totalSpot={value.totalSpot}
                accessLevel={value.accessLevel}
                startTime={value.startDate}
                startPrice={value.startPrice}
                priceDrop={value.priceDrop}
                floorPrice={value.floorPrice}
                socket={socket}
                CurrentSupplyForEachToken={value.CurrentSupplyForEachToken}
              />
            ))}
        </div>
      ) : (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center ">
          <h1>There are no items in purchase</h1>
        </div>
      )}
    </div>
  );
};
