import { useMetaMask } from "metamask-react";
import React, { useEffect, useState } from "react";
import "../../css/ProjectPurchases.css";
import { Spinner } from "react-bootstrap";
import { getPurchased } from "../../utils/backendApi";

import PurchaseCard from "./PurchaseCard";
export const ProjectPurchases = ({ list }) => {
  const { account } = useMetaMask();
  const [purchasedItem, setpurchasedItem] = useState(null);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    const callFunc = async () => {
      setpurchasedItem(await getPurchased(account));
      setLoading(false);
    };
    callFunc();
  }, [account]);
  return (
    <>
      {loading ? (
        <div className="projectPurchase_loader">
          <Spinner
            as="span"
            animation="border"
            className="loader"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <div className="projectPurchase justifyContentodd">
          <div
            className="projectPurchase_scrollview m-2"
            style={{ justifyContent: "center" }}
          >
            {purchasedItem && purchasedItem?.purchased.length > 0 ? (
              purchasedItem?.purchased.map((item, index) => (
                <PurchaseCard item={item} />
              ))
            ) : (
              <p className="no-item-flex">"No Purchased Items"</p>
            )}
          </div>
        </div>
      )}
    </>
  );
};
