import axios from 'axios'
import {toast} from 'react-toastify'

const BASE_URL = 'https://yetimarketbackend.0xytocin.online/'
export const getProjects = async() => {
    const {data} = await axios.get(BASE_URL+'projects')
    return data
}
export const getCurrentPrice =async (id) => {
    const {data} =await axios.post(BASE_URL+'currentprice',{tokenId:id})
    return data
}

export const getmaxLevel = async(address) => {
    try{
        console.log(address);
        const { data } = await axios.post(BASE_URL + "level", {
          address
        });
        console.log(data);
        return data
    }
    catch(error){
        if (error.response && error.response.data)
          console.error(error.response.data);
    }
}

export const getSigner = async(address,tokenId) => {
    try{
        const {data} = await axios.post(BASE_URL+'signer',{
            address:address,
            tokenId:tokenId
        })
        console.log(data);
        return data
    }
    catch(error){
        console.error(error.response.data);
        return false
    }
}
export const getPurchased = async (address) => {
  try {
    const { data } = await axios.post(BASE_URL + "purchased", {
      address: address,
    });
    console.log(data);
    return data;
  } catch (error) {
    console.error(error.response.data);
    return false;
  }
};

export const gasLessTransaction = async (address,tokenId,sign)=>{
  try{
    toast.loading("Processing");
    const {data} = await axios.post(BASE_URL+'gasLessTransaction',{
        address:address,
        tokenId:tokenId,
        sign:sign
    })
    toast.dismiss();
    toast.success("Transaction Completed");
    console.log(data);
    return data
}
catch(error){
    toast.dismiss();
    toast.error("Transaction Failed");
    console.error(error.response.data);
    return false
}
}