import "./App.css";
import { Homepage } from "./Components/Homepage";
import { InventoryDashboard } from "./Components/Dashboard/Inventory/InventoryDashboard";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const App = () => {
  return (
    <div className="App">
      <ToastContainer />
      <Router>
        <Switch>
          <Route exact path={"/"} component={Homepage} />
          <Route exact path={"/inventory"} component={InventoryDashboard} />
        </Switch>
      </Router>
      <footer>
        {/* <p className={"footer_text"}>Copyright, 2021 - Skyverse Marketplaces NFT</p> */}
      </footer>
    </div>
  );
};

export default App;
