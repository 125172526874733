import React, { useEffect } from "react";
import "../css/Homepage.css";
import { useMetaMask } from "metamask-react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

let userChainId = 137

export const Homepage = () => {
  const history = useHistory();
  const { status, connect, chainId } = useMetaMask();
  
  useEffect(() => {
    if (status === "connected" && chainId === "0x89") {
      toast("Login successful");
      history.push("/inventory");
    } else {
      history.push("/");
    }
  }, [status]);
  const addNewChain = async () => {
    console.log(`qaddnewchain`);
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: `0x${userChainId.toString(16)}`,
          rpcUrls: ["https://rpc-mainnet.maticvigil.com"],
          chainName: "Matic Mainnet",
          nativeCurrency: "MATIC",
        },
      ],
    });
  };
  const switchCain = async () => {
    console.log(`switchchain`);
    await window.ethereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: `0x${userChainId.toString(16)}` }],
    });
  };
  const promptChain = async () => {
    toast.dismiss();
    try {
      await switchCain();
    } catch (e) {
      await addNewChain();
      // await switchCain();
    }
  };
  console.log("chainId", chainId);
  const handleClick = async () => {
    
    if (chainId === "0x89") {
      await connect()
        .then(async () => {
          status === "connected" && history.push("/inventory");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.warning(`You are not connected with MATIC Mainnet`);
      await promptChain();
    }
  };

  return (
    <div className={"homepage"}>
      <header className={"homepage_header"}>
        <img
          src={"/images/newLogo.png"}
          alt={"Yeti Marketplaces"}
          className={"logo"}
        />
        <p className="description">
          {/* Community tools to breed, evolve and ascend your Yeti Marketplace NFT. */}
          Stake, level up, and earn $FRXST to spend on various utilities.
        </p>
      </header>
      <main className={"wallet_connection"}>
        <section className={"wallet_section"}>
          <button className={"connect_wallet"} onClick={handleClick}>
            Connect Wallet
          </button>
          <h4 className={"wallet_description"}>
            {/* Connect the wallet that holds your Marketplace. */}
            Connect to the wallet that holds your Yetis.
          </h4>
        </section>
      </main>
    </div>
  );
};
