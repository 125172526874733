import React, { useState, useEffect } from "react";
import "../../css/Dashboard.css";
import { Dropdown, Spinner } from "react-bootstrap";
import { wrapString } from "../../utils/helper";
import { ProjectTiles } from "./ProjectTiles";
import { ProjectPurchases } from "./ProjectPurchases";
import {
  approveFRXST,
  isApproveFRXST,
  providerHandler,
} from "./../../contracts/contractInteraction.js";
import { getProjects } from "../../utils/backendApi";
import "../../css/ProjectDetails.css";
import filtericon from "../../assets/img/filter.svg";

export const Dashboard = ({ balanceAmount, amount, walletAddress, level }) => {
  const handleApprove = () => {
    approveFRXST();
  };
  let [selectedSection, setSelectedSection] = useState("market");
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [mainlist, setMainlist] = useState([]);
  const [projectList, setProjectList] = useState([]);
  const [filters, setfilters] = useState(false);
  const [whiteList, setwhiteList] = useState([]);
  const [rootList, setRootList] = useState([]);
  const [tool, setTool] = useState([]);
  const [show, setShow] = useState(true);

  useEffect(() => {
    const getList = async () => {
      setLoading(true);
      const data = await getProjects();
      await providerHandler();
      console.log(data);
      const filtered =
        data.length > 0 &&
        data.filter(
          (item) => item.CurrentSupplyForEachToken !== item.totalSpot
        );
      const filterSold =
        data.length > 0 &&
        data.filter(
          (item) => item.CurrentSupplyForEachToken === item.totalSpot
        );
      const sequenceData = [...filtered, ...filterSold];
      setProjectList(sequenceData);
      setMainlist(data);
      setTimeout(async() => {
        setLoading(false);
        setShow(await isApproveFRXST());
      }, 500);
    };

    getList();
  }, []);

  useEffect(() => {
    if (selected.length) {
      setProjectList(mainlist.filter((data) => selected.includes(data._id)));
    } else {
      setProjectList(mainlist);
    }
  }, [selected]);

  const docs = () => {
    let a = document.createElement("a");
    a.target = "_blank";
    a.href = "https://docs.yetitown.com/marketplace-guide";
    a.click();
  };

  return (
    <div className={"dashboard"}>
      <header className={"dashboard_header"}>
        <div className="w-25">
          <img
            src={"/images/newLogo.png"}
            alt={"Yeti Marketplaces"}
            className={"dashboard_logo"}
          />
        </div>

        <div className="d-flex align-items-center">
          <div className="wallet_detail d-flex me-5">
            <p className="wallet_bal">BALANCE</p>
            <p className="wallet_balance no-wrap">{amount} $FRXST</p>
          </div>
          <div className="me-5">
            <p
              className="wallet_type no-wrap cursor-pointer"
              onClick={() =>
                window
                  .open("https://wallet.polygon.technology/bridge", "_blank")
                  .focus()
              }
            >
              FRXST BRIDGE
            </p>
          </div>
          <div
            className="wallet_detail d-flex me-5 cursor-pointer"
            onClick={() => docs()}
          >
            <p className="wallet_bal">DOCS</p>
          </div>
          <p className="btn-connect connected-string">
            {wrapString(walletAddress)}
          </p>
          {!show && (
            <div>
              <p className="wallet_type_button" onClick={handleApprove}>
                Approve
              </p>
            </div>
          )}
        </div>
      </header>
      <main className="dashboard_main">
        <div className="d-flex align-items-center justify-content-between me-5 pe-5">
          <div className="dashboard_options">
            <h2
              className={
                selectedSection === "market"
                  ? "dashboard_option selected"
                  : "dashboard_option"
              }
              onClick={() => setSelectedSection("market")}
            >
              MARKET
            </h2>
            <h2
              className={
                selectedSection === "purchases"
                  ? "dashboard_option selected"
                  : "dashboard_option"
              }
              onClick={() => setSelectedSection("purchases")}
            >
              PURCHASES
            </h2>
          </div>
          <div className="filtercard">
            <Dropdown>
              <Dropdown.Toggle
                variant="dark"
                className="filter_button"
                id="dropdown-basic"
              >
                Filters
                <span>
                  <img src={filtericon} alt="" className="ps-3 " />
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu className="dropdown-item-menu">
                <Dropdown.Item
                  onClick={() => {
                    const whiteList = projectList.filter(
                      (li) => li.type === "DA"
                    );
                    setfilters(true);
                    setwhiteList(whiteList);
                    setRootList([]);
                    setTool([]);
                  }}
                >
                  Whitelist
                </Dropdown.Item>
                <Dropdown.Divider />

                <Dropdown.Item
                  onClick={() => {
                    const root = projectList.filter((li) => li.type === "RT");
                    setfilters(true);
                    setRootList(root);
                    setwhiteList([]);
                    setTool([]);
                  }}
                >
                  {" "}
                  Raffles{" "}
                </Dropdown.Item>
                <Dropdown.Divider />

                <Dropdown.Item
                  onClick={() => {
                    const tool = projectList.filter((li) => li.type === "TOOL");
                    setfilters(true);
                    setTool(tool);
                    setRootList([]);
                    setwhiteList([]);
                  }}
                >
                  Tools
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item onClick={() => setfilters(false)}>
                  Reset
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="dashboard_container">
          {selectedSection === "market" ? (
            <>
              {loading ? (
                <div className="dashboard_loader">
                  <Spinner
                    as="span"
                    animation="border"
                    className="loader"
                    role="status"
                    aria-hidden="true"
                  />
                </div>
              ) : (
                <>
                  {loading ? (
                    <div className="dashboard_loader">
                      <Spinner
                        as="span"
                        animation="border"
                        className="loader"
                        role="status"
                        aria-hidden="true"
                      />
                    </div>
                  ) : (
                    <ProjectTiles
                      balanceAmount={balanceAmount}
                      dashboardLoading={setLoading}
                      list={
                        filters
                          ? whiteList.length > 0
                            ? whiteList
                            : rootList.length > 0
                            ? rootList
                            : tool
                          : projectList
                      }
                      amount={amount}
                    />
                  )}
                </>
              )}
            </>
          ) : (
            <ProjectPurchases />
          )}
        </div>
      </main>
    </div>
  );
};
