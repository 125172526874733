/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useMetaMask } from "metamask-react";
import { balanceOfFRXST, buyNFTs } from "../../contracts/contractInteraction";
import { getCurrentPrice, getSigner, gasLessTransaction } from "../../utils/backendApi";
import { OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";

import { hasPurchased } from "../../contracts/contractInteraction";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { TRUE } from "sass";
import {transactionSigner} from "../../utils/signer"


export default function NewCard(props) {
  const [loading, setLoading] = useState(true);
  const [details, setDetails] = useState(false);
  const [priceLoading, setPriceLoading] = useState(true);
  const { account } = useMetaMask();
  const [text, setText] = useState("BUY");
  const [disable, setDisable] = useState(false);
  const [currentAmount, setCurrentAmount] = useState(false);
  const [time, setTime] = useState(props.startTime);
  const [started, setStarted] = useState(false);
  const [info, setInfo] = useState(false);
  const [curPrice, setCurPrice] = useState(null);
  const [disableMessage, setDisableMessage] = useState(null);
  const [notStartedText, setNotStartedText] = useState(null);

  const callback = (data) => {
    timeDiff(props.startTime);
    const temp = moment
      .utc((data.next - moment().unix()) * 1000)
      .format("HH:mm:ss");
    setTime(temp);
    if (data.price !== currentAmount) {
      setCurrentAmount(data.price);
      setPriceLoading(false);
    } else {
      setCurrentAmount(currentAmount);
      setPriceLoading(false);
    }
  };

  const getCurPrice = async (id) => {
    setPriceLoading(true);
    let value = await getCurrentPrice(id);
    setCurPrice(value);
    setPriceLoading(false);
  };

  useEffect(() => {
    props.socket.on(props.value.tokenId, callback);
    return () => {
      props.socket.removeListener(props.value.tokenId, callback);
    };
  }, []);
  const sellNotStarted = (startdate) => {
    var startTime = new Date(startdate * 1000).getTime();
    var currentTime = new Date().getTime();
    if (startTime > currentTime) return true;
    else return false;
  };
  useEffect(() => {
    if (sellNotStarted(props.value.startDate)) {
      setNotStartedText("Not started");
      setDisable(true);
    }
    if (props?.value?.CurrentSupplyForEachToken === props.value.totalSpot) {
      setText("SOLD");
      setDisable(true);
    }
    async function preload() {
      // console.log("level", props.value.projectName)
      // console.log("level check",props.maxlevel >= props.value.accessLevel)
      // console.log("level", props.value.accessLevel)
      // console.log("level max",props.maxlevel)
      if (props.maxlevel >= props.value.accessLevel) {
        console.log(props.value.currentPrice);
        console.log(props.value.projectName);
        console.log(props.amount);
        console.log(props.value.currentPrice[0] >= props.amount);
        if (
          props.amount &&
          props.value.currentPrice &&
          props.value.currentPrice[0] >= props.amount
        ) {
          setDisable(true);
          setDisableMessage("Insufficient FRXST balance");
        } else {
          const v = await hasPurchased(account, props.value.tokenId);
          if (v) {
            setDisable(v);
            setText("PURCHASED");
            setDisableMessage("You have already purchased");
          }
        }
      } else {
        if (text === "SOLD") {
          setDisableMessage("");
        } else {
          setDisableMessage("Yeti level criteria is not met");
        }
        setDisable(true);
      }
      setLoading(false);
    }
    preload();
    if (currentAmount === false) setPriceLoading(false);
  }, [loading, currentAmount, curPrice,props.amount]);

  const buy = async (tokenid, amount) => {

    props.dashboardLoading(true)
    //const sign = await getSigner(account, tokenid);
    const sign = await transactionSigner(account, tokenid)
    console.log([account, tokenid, sign],'sign')
    if (sign) {
      setShow(false);
      setLoading(true);
      //await buyNFTs(account, amount, tokenid, sign);
      const n = await gasLessTransaction(account,tokenid,sign)
      setLoading(false);
      let check = await balanceOfFRXST();
      props.balanceAmount(check);
      setDisable(true);
    }
    props.dashboardLoading(false);
  };

  const timeDiff = (starttime) => {
    const mytime = starttime - moment().unix();
    if (mytime < 0) setStarted(true);
  };
  const [show, setShow] = useState(false);
  const handleClose = async () => setShow(false);
  const handleShow = () => {
    getCurPrice(props.value.tokenId);
    setShow(true);
  };

  const startTime = (seconds) => {
    const milliseconds = seconds * 1000;
    const dateObject = new Date(milliseconds);
    return dateObject.toUTCString();
  };

  return (
    <>
      <div className={`card ${text === "SOLD" ? "blur-image" : ""} m-2`}>
        <div className={`img-card   position-relative`}>
          {props.value.totalSpot && (
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={
                <Tooltip id={`tooltip-bottom`}>
                  {props.value.totalSpot -
                    props.value.CurrentSupplyForEachToken}{" "}
                  Left
                </Tooltip>
              }
            >
              <div
                className="point_text position-absolute bg-dark px-4 cursor-pointer "
                dataToggle="tooltip"
                dataPlacement="bottom"
                // title={`${props.value.totalSpot} Left`}
              >
                {props.value.CurrentSupplyForEachToken.toString()}/
                {props.value.totalSpot}
              </div>
            </OverlayTrigger>
          )}
          <div
            className="right-text position-absolute top-0  px-2 ps-3 cursor-pointer"
            style={{
              backgroundColor:
                props.value.type === "DA"
                  ? "yellow"
                  : props.value.type === "RT"
                  ? "green"
                  : "red",
              color: props.value.type === "DA" ? "black" : "",
            }}
            onMouseEnter={() =>
              setDetails(
                props.value.type === "DA"
                  ? true
                  : props.value.type === "RT"
                  ? true
                  : false
              )
            }
            onMouseLeave={() => setDetails(false)}
          >
            <span>{props.value.type}</span>
          </div>
          {details && (
            <div className="position-relative">
              <span
                className={`position-absolute end-0 top-25`}
                style={{
                  backgroundColor:
                    props.value.type === "DA"
                      ? "yellow"
                      : props.value.type === "RT"
                      ? "green"
                      : "red",
                  color: props.value.type === "DA" ? "black" : "",
                }}
              >
                {props.value.type === "DA"
                  ? "Dutch Auction"
                  : props.value.type === "RT"
                  ? "Raffle Ticket"
                  : "Tools"}
              </span>
            </div>
          )}
          <div className="img-between text-center">
            <img src={props.value.image_url} className="card_img" alt="" />
          </div>
          {props.value.isAuction && (
            <div
              className="bottom-right-text mb-2 position-absolute bottom-0 end-0"
              onMouseEnter={() => setInfo(true)}
              onMouseLeave={() => setInfo(false)}
            ></div>
          )}
        </div>
        {info && props.value.isAuction ? (
          <div className="card-footer-bg p-2 my-2 ">
            <div className="row align-self-center pb-1">
              <div className="col-6">
                <h5 className="text-white">Eligibility</h5>
                <span>{`Level ${props.value.accessLevel}`}</span>
              </div>
              <div className="col-6 p-0">
                <h5 className="text-white">Start time</h5>
                <span>{startTime(props.value.startDate)}</span>
              </div>
            </div>
            <div className="row align-self-center mt-1 pb-1">
              <div className="col-6">
                <h5 className="text-white">Start price</h5>
                <span>{`${props.value.startPrice} $FRXST`}</span>
              </div>
              <div className="col-6 p-0">
                <h5 className="text-white">Final price</h5>
                <span>{`${props.value.floorPrice} $FRXST`}</span>
              </div>
            </div>
            <div className="row align-self-center mt-1 pb-1">
              <div className="col-6">
                <h5 className="text-white">Price drops</h5>
                <span>{`Every 30 min`}</span>
              </div>
              <div className="col-6 p-0">
                <h5 className="text-white">Price drops by</h5>
                <span>{`${props.value.priceDrop} $FRXST`}</span>
              </div>
            </div>
          </div>
        ) : (
          <div className="card-footer ">
            <div className="top-text">
              <h5
                className="text-white text-center cursor-pointer"
                title={props.value.projectName}
              >
                {props.value.projectName.slice(0, 15)}
                {props.value.projectName.length > 15 ? "..." : ""}
              </h5>
            </div>
            <div className="center-text price_div d-flex align-items-center p-2">
              {!notStartedText ? (
                <div className="row align-items-center">
                  <div className="col-5">
                    <div className="text-white">Current Price</div>
                  </div>
                  <div className="col-7 price-value">
                    {curPrice
                      ? curPrice?.currentPrice
                      : props.value.currentPrice && props.value.currentPrice[0]}
                    <span className="ms-1">$FRXST</span>
                  </div>
                </div>
              ) : (
                <div className="col-12" style={{ lineHeight: "3rem" }}>
                  <div className="text-white text-center">{notStartedText}</div>
                </div>
              )}
            </div>
            <div className="bottom-button  text-white text-center mb-2 py-2">
              <button
                className={`${
                  disable ? "bg-secondary buy_btn-disable" : "buy_btn"
                }  btn text-white w-100 d-flex align-items-center mb-1 justify-content-center`}
                disabled={disable}
                style={{ fontSize: text == "Sell not started" ? "1.5rem" : "" }}
                onClick={handleShow}
              >
                {/* {props?.value?.CurrentSupplyForEachToken ==
                props?.value?.totalSpot
                  ? "SOLD"
                  : text
                  ? text
                  : "BUY"} */}
                {text ? text : "BUY"}
              </button>
              {disable && <p>{disableMessage && disableMessage}</p>}
            </div>
            {text === "SOLD" && <p className="blur-text">SOLD</p>}
          </div>
        )}

        {/* ---modal--- */}

        <Modal show={show} centered size="lg" backdrop="static">
          <div className="buy_modal text-white position-relative px-4 py-4">
            <div className="modal_close" onClick={handleClose}></div>
            <Modal.Body>
              <div className="text-center">
                <h1>Disclaimer</h1>
              </div>

              <div className="modal_contents py-4">
                <p>
                  All purchases in the marketplace is at your own risk. They are
                  final and there will be no refunds. Please ensure you do your
                  own research and submit the right details and information.
                </p>
                <p>
                  Each wallet can only purchase one spot for each item. Do not
                  refresh or close the window until the transaction is
                  confirmed.
                </p>
                <p>
                  Once you have made a purchase, your wallet address will be
                  recorded and you will see the item under the 'Purchased' tab.
                </p>
              </div>

              <div className="bottom-button w-100 text-center d-flex align-items-center justify-content-center text-white mb-2 py-2">
                <button
                  className="proceed_button btn text-white"
                  onClick={() =>
                    buy(props.value.tokenId, curPrice.currentPrice)
                  }
                >
                  Proceed
                </button>
              </div>
            </Modal.Body>
          </div>
        </Modal>
      </div>
    </>
  );
}
