import React from "react";
import ReactDOM from "react-dom";
import { MetaMaskProvider } from "metamask-react";
import "react-toastify/dist/ReactToastify.css";

import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App";
import "./fonts/Press_Start_2P/PressStart2P-Regular.ttf";
import "./fonts/Pixellari/Pixellari.ttf";
ReactDOM.render(
  <React.StrictMode>
    <MetaMaskProvider>
      <App />
    </MetaMaskProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
