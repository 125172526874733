import { useMetaMask } from "metamask-react";
import React, { useEffect, useState } from "react";
import {
  providerHandler,
  balanceOfFRXST,
} from "../../../contracts/contractInteraction";
import "../../../css/Dashboard.css";
import { Dashboard } from "../Dashboard";
import {getmaxLevel} from "../../../utils/backendApi"
export const InventoryDashboard = ({
  setWalletAddress,
}) => {
  const { account,status } = useMetaMask();
  const [amount, setAmount] = useState(0)
  const [level, setLevel] = useState(false)
  useEffect(() => {
    const handleCall = async () => {
      const {maxLevel}=await getmaxLevel(account)
      setLevel(maxLevel)
      await providerHandler();
      setAmount(await balanceOfFRXST())
    };
    if (status === "connected")
    handleCall();
  }, [status]);
  setWalletAddress = account;
  return <Dashboard walletAddress={account} balanceAmount={setAmount} level={level} amount={amount} />;
};
