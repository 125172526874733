import { ethers } from "ethers";
import yetiAbi from "./YetiTown.json";
import gameLogicAbi from "./gameLogic.json";
import FRXSTAbi from "./FRXST.json";
import ERC1155Abi from "./ERC1155.json";
import { toast } from "react-toastify";
let address,
  yetiTownAddresses,
  yetiTownContract,
  gameLogicAddress,
  gameLogicContract,
  FRXSTaddress,
  FRXSTcontract,
  ERC1155address,
  ERC1155contract;

export const providerHandler = async () => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const provider2 = new ethers.providers.AlchemyProvider(
    "mainnet",
    "erAzKdIjxQDFEVhlCqXKEZkmX0UD-yvb"
  );

  const account = await provider.listAccounts();
  address = account[0];
  const signer = provider.getSigner();

  yetiTownAddresses = "0x65c5493E6D4D7bf2dA414571EB87ed547Eb0AbeD"; //yeti contract
  gameLogicAddress = "0x8Ee4eF84c95f0c51c5654394137a703bF9277072"; // gameLogic contract
  FRXSTaddress = "0xAd7153e9B8C227ec8311Ba781cAA6Edb623C7475"; //FRXST contract
  // ERC1155address = "0xeB10dd186681709cDf99D10d10870be7BF3d2B1D"; //ERC1155 address
  ERC1155address = "0xECa09d089EDa054A0696C2d7944ccee58f5F5b9F"; //ERC1155 address

  yetiTownContract = new ethers.Contract(yetiTownAddresses, yetiAbi, provider2);
  gameLogicContract = new ethers.Contract(
    gameLogicAddress,
    gameLogicAbi,
    provider2
  );
  ERC1155contract = new ethers.Contract(ERC1155address, ERC1155Abi, signer);
  FRXSTcontract = new ethers.Contract(FRXSTaddress, FRXSTAbi, signer);

  console.log("contracts init done");
};

// function get the NFT's currently held by the user in yetiTown contract (unstaked)
export const yetiTownHold = async () => {
  const nftCount = await yetiTownContract.balanceOf(address);
  console.log("nftCount", nftCount.toNumber());
  if (nftCount.toNumber() === 0) {
    return false;
  } else {
    let nft_list = [];
    for (let i = 0; i < nftCount.toNumber(); i++) {
      const n = await yetiTownContract.tokenOfOwnerByIndex(address, i);
      nft_list.push(n.toNumber());
    }
    console.log(nft_list);
    return nft_list;
  }
};
export const hasPurchased = async (address, tokenId) => {
  await providerHandler();
  const n = await ERC1155contract.hasPurchased(address, tokenId);
  return n;
};
export const gameLogicHold = async () => {};

//FRXSTcontract
//Function to get FRXST balance of a wallet address, returns balance value
export const balanceOfFRXST = async () => {
  const n = await FRXSTcontract.balanceOf(address);
  console.log("FRXSTBalance", ethers.utils.formatEther(n));
  return Number(ethers.utils.formatEther(n));
};

//ERC1155 contract

export const buyNFTs = async (address, amount, tokenId, signature) => {
  try {
    console.log([address,
      tokenId,
      amount * 100,
      signature])
    toast.loading("Processing");
    const n = await ERC1155contract.buyNFTs([
      address,
      tokenId,
      amount * 100,
      signature,
    ]);
    await n.wait();
    toast.dismiss();
    toast.success("Transaction success");
    return n;
  } catch (error) {
    console.log(error)
    toast.dismiss();
    toast.error(error.message);
  }
  
};

export const approveFRXST = async () => {
  try {
    toast.loading('Approving');
    const n = await FRXSTcontract.approve(
      ERC1155address,
      "1000000000000000000000000"
    );
    await n.wait();
    toast.dismiss()
    toast.success('Approved');
    return n;
  } catch (error) {
    toast.dismiss();
    toast.error(error.message);
  }
  
};

export const isApproveFRXST = async () => {
  const n = await FRXSTcontract.allowance(address, ERC1155address);
  console.log('allowance', n.toString());
  return n.toString() !== "10000000000000000000000" ? false : true;
};
