import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  PURCHASE_DA,
  PURCHASE_RT,
  PURCHASE_TOOL,
} from "../../utils/constants/constants";

export default function PurchaseCard({ item }) {
  const [show, setShow] = useState(false);
  return (
    <div
      key={item._id}
      className="cardContainer m-2"
      style={{ position: "relative" }}
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      <img
        src={item.image_url}
        alt={item.projectName}
        className={"newCard_logo"}
      />

      <OverlayTrigger
        placement="bottom"
        delay={{ show: 250, hide: 400 }}
        overlay={<Tooltip id={`tooltip-bottom`}>{item.projectName}</Tooltip>}
      >
        <p className="newCard_headerText">
          {item.projectName.length > 11
            ? `${item.projectName.slice(0, 10) + "..."}`
            : item.projectName}
        </p>
      </OverlayTrigger>

      <button className="newCard_button">Token Id. {item.tokenId}</button>
      {show && (
        <div className="">
          <span
            className={`position-absolute top-25`}
            style={{
              backgroundColor:
                item.type === "DA"
                  ? "yellow"
                  : item.type === "RT"
                  ? "green"
                  : "red",
              color: item.type === "DA" ? "black" : "",
              right: "2.5rem",
              left: "2.5rem",
              top: "5rem",
            }}
          >
            {item.type === "DA"
              ? PURCHASE_DA
              : item.type === "RT"
              ? PURCHASE_RT
              : PURCHASE_TOOL}
          </span>
        </div>
      )}
    </div>
  );
}
