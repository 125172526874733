require("dotenv").config();
const ethers = require("ethers");

const domain = {
  name:"Yeti-Marketplace",
  version: "1",
  chainId: 137,
  verifyingContract: "0xECa09d089EDa054A0696C2d7944ccee58f5F5b9F",
};
const types = {
  Transaction: [
    { name: "userAddress", type: "address" },
    { name: "tokenId", type: "uint256" },
  ],
};
export async function transactionSigner(address,tokenId) {
    try {
      console.log(address,tokenId)
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      //const account = await provider.listAccounts();
      //const address = account[0];
      const value = {
        userAddress: address,
        tokenId: tokenId,
      };
      const sign = await signer._signTypedData(domain, types, value);
      console.log([domain,sign]);
      return sign;
    } catch (e) {
      console.log(e);
    }
}


